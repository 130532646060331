@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  margin: 0;
  padding: 0;

  height: 100vh;
  width: 100vw;

  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.-rotate-30 {
  transform: rotate(-30deg);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.vertical-crop .ReactCrop,
.vertical-crop .ReactCrop > div,
.vertical-crop .ReactCrop__image {
  height: 100%;
}

@font-face {
  font-family: 'rampart';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('assets/fonts/Rampart_One Font/RampartOne-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('assets/fonts/Roboto Font/Roboto-Regular.ttf')
      format('truetype');
}